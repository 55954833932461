@tailwind base;
@tailwind components;
@tailwind utilities;
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	scroll-behavior: smooth;
	font-family: 游ゴシック;
}
html{
    scroll-behavior: smooth;
	scrollbar-color: #6969dd #e0e0e0;
	scrollbar-width: thin;

}
body{
    margin: 0;
    position: relative;
    overflow-x: hidden;
	background: #f4f3ed;
	-webkit-print-color-adjust:exact !important;
    print-color-adjust:exact !important;
}
::-webkit-scrollbar {
	width: 10px;
  }
  
  ::-webkit-scrollbar-track {
	background-color: rgb(221, 218, 218);
  }
  
  ::-webkit-scrollbar-thumb {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
  }

  .siderbar:hover  .button{
    background-color: black;
		color: white;
  }

	#intro {
		-moz-animation: fadeOut 1.5s ease;
		-webkit-animation: fadeOut 1.5s ease;
		animation: fadeOut 1.5s ease;
		transition: margin 100ms ease;
	}
  